/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set default font family, text color, and line height for better readability */
body {
  font-family: 'Arial', sans-serif;
  color: #FFFFFF;
  line-height: 1.6;
  font-size: 16px;
  background-color: #1A202C;
}

/* Add some padding to the main container */
#root {
  padding: 20px;
}

/* Improved button styles */
.button {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.button-primary {
  background-color: #E53E3E;
  color: white;
}

.button-primary:hover {
  background-color: #C53030;
}

/* Header styles */
.header {
  background-color: #2C7A7B;
  padding: 15px 0;
}

.nav-item {
  color: white;
  margin-right: 20px;
  font-size: 18px;
}

/* Hero section styles */
.hero {
  position: relative;
  color: white;
  text-align: center;
  padding: 100px 0;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero p {
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 15px;
  color: #FFFFFF;
}

h1 { font-size: 36px; }
h2 { font-size: 30px; }
h3 { font-size: 24px; }

/* Add a complementary color */
.accent-color {
  color: #4FD1C5;
}

/* Improve text readability */
p, li, span {
  color: #E2E8F0;
}

/* Enhance contrast for links */
a {
  color: #63B3ED;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
